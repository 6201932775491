export const getProductImageURL=(product,notRenderThumbanail)=>{
      
    if(product?.image?.includes('uploads')){

        let toReturn = product.image

        if(toReturn.includes('webp') && !notRenderThumbanail){

          const splitData = product.image.split('.webp')
          toReturn = `${splitData[0]}_th.webp`

        } 
        
        return toReturn

    }else{

      const imageURL = `https://ivyinteractive.co/Rozaana_live/ondeman_services/categoryimages/${product?.subcatID ?? ''}/${product?.subcat_multiselectid ?? ''}/${product?.uid ?? ''}.jpg`
      return imageURL
    }
  }


  export const genderOption=[
    {value:'Male',label:'Male'},
    {value:'Female',label:'Female'},
]


export const GOOGLE_MAPS_API_KEY = 'AIzaSyBLF0RwcAGOFci1AcualqfbADtcio-omxw'

export const inputLabels = [

    'button',
    'text_field',
    'time',
    'location',
    'list',
    'multiselect',
    'image',
    'audio'

]


export const convertStringToLatLng=(loc)=>{

  if(loc?.length){

      let LatLngs = loc.split(',')
          if(LatLngs.length==2)
          {
          
              let c = {
                  lat:parseFloat(LatLngs[0]),
                  lng:parseFloat(LatLngs[1])
              }
              return c;
          }

  }
}

export const getSumByKey = (data, key) => {
  if (data) {

      return data
          .map((d) => (isNaN(d[key]) ? 0 : d[key]))
          .reduce((a, b) => a + b, 0)
  }
}


export const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})

export const getFormattedDate=(a)=>{

  var date = new Date(a);
  var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric',second:'numeric', hour12: false};
  var dateString = date.toLocaleString('sv-SE', options);

  return dateString
}

export const formatDate=(time)=>{

    const date = new Date(time);
    const formattedDate = date.toLocaleDateString("en-US", { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedTime = date.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric', hour12: true });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    
    return formattedDateTime
    // Output: "02-May-2023 4:45:05 PM"

}