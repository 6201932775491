import React, { createContext, FC, useContext, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsContext = createContext({ isLoaded: false });

export const GoogleMapsProvider = ({ children, ...loadScriptOptions }) => {
    const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);

    const value = useMemo(() => ({ isLoaded, loadError }), [isLoaded, loadError]);
    
    return (
        <GoogleMapsContext.Provider value={value}>
            {children}
        </GoogleMapsContext.Provider>
    );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);