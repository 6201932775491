import Services from "./Services";

export const getCityData = async () => {
    try {
      
        const response = await Services.getManageCityData()
        
        const data = await response.data
        
        return data

    } catch (err) {
        console.log('ERROR in manageData getCityData', err)
    }
}


export const getCategoryData = async () => {
    try {
        const response = await Services.getManageCategoryData()
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData getCategoryData', err)
    }
}

export const getSubCategoryData = async () => {
    try {
        const response = await Services.getManageSubCategoryData()
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData getSubCategoryData', err)
    }
}
