import { createSlice } from "@reduxjs/toolkit"
import { updateShopLocalData } from "../../redux/action"



const CheckoutSlice=createSlice({

    name:'Checkout',
    initialState:{
        
        captchaID:Math.random(),
        customerInfo:{
            
            name:'',
            id:'',
            paymentType:'',
            
        },
        promo:-1,
        bookingData:[],
        currentPosition:-1,
        timeSlotData:[],
        timeSlotValues:{
            lagTime:30,
            startTime:'09:00',
            endTime:'09:00'
        },
        timeSlotLoading:false

    },
    reducers:{

        setCaptchaID:(state,action)=>{

            state.captchaID = action.payload

        },
        setTimeSlotLoading:(state,action)=>{

            state.timeSlotLoading = action.payload

        },
        setTimeSlotData:(state,action)=>{

            state.timeSlotData = action.payload

        },
        setTimeSlotValues:(state,action)=>{

            state.timeSlotValues = {...state.timeSlotValues,...action.payload}

        },
        setCustomerInfo:(state,action)=>{

            state.customerInfo = {...state.customerInfo,...action.payload}

        },
        setPromo:(state,action)=>{

            state.promo = action.payload

        },
        setBookingData:(state,action)=>{

            state.bookingData = action.payload
        
        },
        setCurrentPosition:(state,action)=>{

            state.currentPosition = action.payload
            updateShopLocalData(action.payload,'currentLocation')
        
        }
    }
})

export const {
    
    setCaptchaID,
    setCustomerInfo,
    setPromo,
    setBookingData,
    setCurrentPosition,
    setTimeSlotData,
    setTimeSlotValues,
    setTimeSlotLoading

} = CheckoutSlice.actions

export default CheckoutSlice.reducer