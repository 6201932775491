import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateShopLocalData } from "./action";
import { sortArrayByKey, sortCompare } from '../../@fake-db/utils';
import Services from "../../utility/api/Services";
import { getCategoryData } from "../../utility/api/Parser";



export const getShopItemsData=createAsyncThunk('shopV2/shopItemsData',async(subCatId)=>{
    
    //const response = //await axios.get('https://ivyinteractive.co/rozana/ondeman_services/mservices/getitemsdata.php?timestamp=2010')
    return Services.getTestlistFromPhp(subCatId).then(response=>{
        const data = response.data.data;
        return data
    });
    
    
    

})

export const getShopHomeRendererData=createAsyncThunk('shopV2/homePageRenderData',async(subCatId)=>{
    
    // /api/getShopHomeRendererData
    //const response = await axios.get('https://ivyinteractive.co/rozana/ondeman_services/mservices/gethomepagedata.php?timestamp=2010')

        return Services.getHomePageData(subCatId).then(response=>{

            if(response){

                const data = response.data.data
                // const sortData = data.sort(sortCompare('sequence'))
                const sortData = sortArrayByKey(data,'sequence').filter(x=>x.publishtype==1)
                
                return sortData
        
            }
        });

        return []
    
})

const ShopV2Slice=createSlice({

    name:'Shop V2',
    initialState:{
        
        homePageRenderData:[],
        homePageRenderLoading:false,

        mainData:-1,
        mainLoading:false,
        filterParams:{
            alphabet:'',
            tag:'',
            type:'',
            startsWith:''
        },

        cityData:[],
        serviceCategoriesData:[],
        serviceSubCategoriesData:[],
        viewServiceCategories:false,

        proceedToShop:false,
        selectedServiceSubCat:-1,

        address:-1,
        city:'',


        ////////////////////////////////////////

        orderDetails:{
            invoice:{

                serviceCharges:0,
                discountType:'',
                discount:0,
                distanceCharges:0,
                productCharges:0
            },
            storyChoosed:{}
        },
        filterParams:{
            alphabet:'',
            tag:'',
            type:'',
            startsWith:''
        },
        orderInvalid:false,
        jobDetail:-1,
        viewNewJobModal:false,
        pixelVisit:false


    },
    reducers:{
        setHomePageRendererData:(state,action)=>{

            state.homePageRenderData = action.payload
        },
        setHomePageMainData:(state,action)=>{

            state.mainData = action.payload
        },
        setParams:(state,action)=>{

            state.filterParams = {...state.filterParams,...action.payload}

        },
        setAddress:(state,action)=>{

            state.address = action.payload
            
            localStorage.removeItem('userAddress')
            localStorage.setItem('userAddress',JSON.stringify(action.payload))

        },
        setCity:(state,action)=>{

            state.city = action.payload
            
            localStorage.removeItem('selectedCityName')
            localStorage.setItem('selectedCityName',action.payload)
        },
        setCityData:(state,action)=>{

            state.cityData = action.payload
        },

        setServiceCategoriesData:(state,action)=>{

            state.serviceCategoriesData = action.payload
            updateShopLocalData(action.payload,'serviceCatsData')

        },
        setServiceSubCategoriesData:(state,action)=>{

            state.serviceSubCategoriesData = action.payload
            updateShopLocalData(action.payload,'serviceSubCatsData')

        },
        setViewServiceCategoriesData:(state,action)=>{

            state.viewServiceCategories = action.payload
            
        },
        setProceedToShop:(state,action)=>{

            state.proceedToShop = action.payload
            
            localStorage.removeItem('proceedToShop')
            localStorage.setItem('proceedToShop',action.payload)
        },
        setSelectedServiceSubCategory:(state,action)=>{

            state.selectedServiceSubCat = action.payload
            localStorage.removeItem('selectedServiceSubCat')
            localStorage.setItem('selectedServiceSubCat',action.payload)
        },
        setOrderDetails:(state,action)=>{

            state.orderDetails = action.payload

        },
        setOrderValidity:(state,action)=>{

            state.orderInvalid = action.payload
            
        },
        setJobDetails:(state,action)=>{

            state.jobDetail = action.payload
        },
        setViewNewJobModal:(state,action)=>{
            
            state.viewNewJobModal = action.payload
        },
        setRefreshShopData:(state,action)=>{

            state.orderDetails = {
                invoice:{
    
                    serviceCharges:0,
                    discountType:'',
                    discount:0,
                    distanceCharges:0,
                    productCharges:0
                },
                storyChoosed:{}
            }
        },
        setPixelVisit:(state,action)=>{
            
            state.pixelVisit = action.payload
        },
    },
    extraReducers:builder=>{
        builder.addCase(getShopHomeRendererData.fulfilled, (state,action)=>{
            
            state.homePageRenderData = action.payload
            state.homePageRenderLoading = false

            updateShopLocalData(action.payload,'homePageRenderData')

        })
        .addCase(getShopHomeRendererData.pending, (state,action)=>{
            
            state.homePageRenderLoading = true
        })
        .addCase(getShopHomeRendererData.rejected, (state,action)=>{
            
            state.homePageRenderData = []
            state.homePageRenderLoading = false

        })

        .addCase(getShopItemsData.fulfilled, (state,action)=>{
            
            state.mainData = action.payload
            state.mainLoading = false

            updateShopLocalData(action.payload,'mainData')

        })
        .addCase(getShopItemsData.pending, (state,action)=>{
            
            state.mainLoading = true
        })
        .addCase(getShopItemsData.rejected, (state,action)=>{
            
            state.mainLoading = false
        })
    }
})


export const {
    
    setHomePageRendererData,
    setParams,
    setAddress,
    setCity,
    setCityData,
    setServiceCategoriesData,
    setServiceSubCategoriesData,
    setViewServiceCategoriesData,
    setProceedToShop,
    setSelectedServiceSubCategory,
    setHomePageMainData,
    setOrderDetails,
    setOrderValidity,
    setJobDetails,
    setRefreshShopData,
    setViewNewJobModal,
    setPixelVisit

} = ShopV2Slice.actions

export default ShopV2Slice.reducer