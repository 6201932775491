export const httpManageCityData = '/manage/city/viewCity'
export const httpManageCategoryData = '/manage/services/category/viewCategory'
export const httpManageSubCategoryData = '/manage/services/subcategory/viewSubCategory'
export const httpgetTestListFromPhp = '/manage/services/testlist/getfromphp'
export const httpgetHomePageData = '/manage/services/gethomepagedataphp'
export const httpManageBookingData = '/manage/services/bookingform/viewBooking'
export const httpGetItemsForShopV2 = '/manage/services/getcitemsdataphp'
export const httpGetItemDetail = '/manage/services/testlist/viewTestList2'



export const httpVerifyCustomer = '/manage/customer/check-by-mobile-no'
export const httpPostRegisterCustomer = '/executions/register-customer'
export const httpPostNewJob = '/executions/post-new-job'
export const httpGetCustomerWallet = "/executions/getCustomerWallet"
export const httpGetOrderDetailById='/executions/get-job-detail-by-id'
export const httpGetPromoCode = '/manage/getPromoCode'
export const httpGetitemsForSearch = '/manage/services/testlist/viewTestList2'
export const httpGetTagsByClassification = '/manage/services/get-tags-by-classification'
export const httpPostAddCustomerOnWheel = '/manage/customer/add-customer-on-wheel'