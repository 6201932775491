import axios from "axios"

const getHeaders=()=>{
    const Token=localStorage.getItem("access_token")
    
    const Headers = {
      headers: {
          "Content-Type": "application/json",
          "accesstoken": '',
          'render':'backend'
      }
    }
    return Headers
}


var Headers=getHeaders()

const refreshHeader=()=>{

  Headers=getHeaders()

}
axios.interceptors.response.use(
  response => response,
  error => {
    console.log(' !! ERR : ',error)
  }
)


export {axios,Headers,refreshHeader};