import { createSlice } from "@reduxjs/toolkit"
import { updateShopLocalData } from "../../redux/action"

const CartSlice=createSlice({

    name:'Shop Cart',
    initialState:{
        
        data:[],
        viewModal:false
     
    },
    reducers:{

        setCartData:(state,action)=>{

            state.data = action.payload
            updateShopLocalData(action.payload,'cart')
        },
        setViewCartModal:(state,action)=>{

            state.viewModal = action.payload
        
        }
    }
})

export const {
    
    setCartData,
    setViewCartModal

} = CartSlice.actions

export default CartSlice.reducer