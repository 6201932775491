import ReactPixel from 'react-facebook-pixel';
import themeConfig from './themeConfig';

export const pixelInit=()=>{
    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
    ReactPixel.init(themeConfig.app.pixelId,  options);
}


export const pixelPageView=()=>{
    console.log('page View')
    
    ReactPixel.pageView();
}

export const pixelAddToCart=(item)=>{
    console.log('pixel add to cart',item)
    let obj={
        content_ids:item.uid,
        content_name:item.genericName,
        currency:'PKR',
        value:item.price
    }
    ReactPixel.track('AddToCart',obj);
}

export const pixelSearch=(item)=>{
    console.log('pixel search',item)
    let obj={
        content_ids:item.uid,
        content_name:item.genericName,
        currency:'PKR',
        value:item.price
    }
    ReactPixel.track('Search',obj);
}

export const pixelPurchase=(order)=>{
    let obj={
        content_ids:order.uid,
        currency:'PKR',
        value:order.price,
        num_items:order.items
    }
    ReactPixel.track('Purchase',obj);
}

