import {httpGetCustomerWallet, httpGetItemDetail, httpGetItemsForShopV2, httpGetOrderDetailById, httpGetPromoCode, httpGetTagsByClassification, httpGetitemsForSearch, httpManageBookingData, httpManageCategoryData, httpManageCityData, httpManageSubCategoryData, httpPostAddCustomerOnWheel, httpPostNewJob, httpPostRegisterCustomer, httpVerifyCustomer, httpgetHomePageData, httpgetTestListFromPhp} from './Constants';
import themeConfig from '../../configs/themeConfig';
import { Headers } from './jwtService';
import axios from 'axios';


const postManageHOF = (manageApi, data,id) => {
    
    let URL = themeConfig.app.baseURL
    if(id){ return axios.post(`${URL}${manageApi}?id=${id}`, data,Headers)}
    
    return axios.post(`${URL}${manageApi}`, data,Headers)
}

// GET

const getManageHOF = (manageApi) => {

    let URL = themeConfig.app.baseURL
    const api = `${URL}${manageApi}`
    return axios.get(api,Headers) 
}


const getManageCityData = () =>getManageHOF(httpManageCityData)
const getManageCategoryData = () => getManageHOF(httpManageCategoryData)
const getManageSubCategoryData = () => getManageHOF(httpManageSubCategoryData)
const getTestlistFromPhp=(subCatId)=> getManageHOF(`${httpgetTestListFromPhp}?subcat_id=${subCatId}`)
const getHomePageData=(subCatId)=> getManageHOF(`${httpgetHomePageData}?subcat_id=${subCatId}`)

const getBookingLabelsData = ()=>getManageHOF(httpManageBookingData)
const getItemsDataForShopV2=(data)=> getManageHOF(`${httpGetItemsForShopV2}?start=${data.start}&end=${data.end}&filter=${data.filter}&filter_value=${data.filterValue}`)
const getItemDetail=(data)=> getManageHOF(`${httpGetItemDetail}?col=${data?.col ?? 'uid'}&ids=${data.ids}`)


const postVerifyCustomer=(contact)=>getManageHOF(`${httpVerifyCustomer}?mobileno=${contact}`)
const postRegisterCustomer = (data)=>postManageHOF(httpPostRegisterCustomer,data)
const postNewJob = (data) => postManageHOF(httpPostNewJob,data)
const getCustomerWallet = (data)=>getManageHOF(httpGetCustomerWallet+"?custId="+data.id)
const getOrderDetails=(data)=>getManageHOF(`${httpGetOrderDetailById}?jobid=${data}`)
const getPromoCodeData = () => getManageHOF(httpGetPromoCode)
const getSearchItems = (itemName)=>getManageHOF(`${httpGetitemsForSearch}?name=${itemName}`)
const getTagsByClassification = (data) => postManageHOF(httpGetTagsByClassification,data)
const postAddCustomerOnWheel = (data) => postManageHOF(httpPostAddCustomerOnWheel,data)

export default {

    getManageCityData,
    getManageCategoryData,
    getManageSubCategoryData,
    getTestlistFromPhp,
    getHomePageData,
    getBookingLabelsData,
    getItemsDataForShopV2,
    getItemDetail,
    postVerifyCustomer,
    postRegisterCustomer,
    postNewJob,
    getCustomerWallet,
    getOrderDetails,
    getPromoCodeData,
    getSearchItems,
    getTagsByClassification,
    postAddCustomerOnWheel
}