import withReactContent from 'sweetalert2-react-content';
import {store} from '../../redux/store';
import { getCategoryData, getCityData, getSubCategoryData } from '../../utility/api/Parser';
import { setAddress,setCity,setJobDetails,setOrderDetails,setOrderValidity,setPixelVisit,setProceedToShop, setRefreshShopData, setServiceCategoriesData, setServiceSubCategoriesData, setViewNewJobModal, setViewServiceCategoriesData } from './store';
import Swal from 'sweetalert2';
import Services from '../../utility/api/Services';
import { setCartData } from '../Cart/redux/slice';
import toast from 'react-hot-toast';
import { setBookingData, setCustomerInfo, setPromo, setTimeSlotData, setTimeSlotLoading, setTimeSlotValues } from '../Checkout/store';
import { useGoogleMaps } from '../../utility/context/GoogleMapsProvider';
import { formatDate, getFormattedDate, getSumByKey, groupByKey } from '../../snippets';
import themeConfig from '../../configs/themeConfig';
import {

    fromLatLng,
    
} from "react-geocode";
import OrderPosted from '../../assets/images/trackOrder/booked.png'
import OrderAssigned from '../../assets/images/trackOrder/assigned.png'
import RiderOnWay from '../../assets/images/trackOrder/transit.png'
import RiderArrived from '../../assets/images/trackOrder/arrived.png'
import OrderCompleted from '../../assets/images/trackOrder/completed.png'
import { pixelAddToCart } from '../../configs/MetaPixels';



export const setUserAddress=(value)=>{

    store.dispatch(setAddress(value))
}



export const setUserCity=(value)=>{

    
    store.dispatch(setCity(value))
}

export const setProceedToShopValue=(value)=>{

    store.dispatch(setProceedToShop(value))

}


export const compareCityName=async(props)=>{

    const {
    
        setLoading,
        cityName,
        cityId,
        setError,
        setView,
        latLng
        
    } = props


    if(setLoading){
        
        setLoading(true)

    }
    
    const storeData = store.getState().ShopV2
    let cities = storeData.cityData

    if(cities.length==0){

        cities = await getCityData()

    }


    try{

        const cityData = [...cities]
        let findCity

        if(cityId){

            findCity = cityData.find(x=>x.cityID==cityId)
        
        }else{
            
            findCity = cityData.find(x=>x.cityName.trim()==cityName.trim())
        }

        if(findCity){

            confirmButtonFunctionForLocation({latLng})
            const catData = await getCategoryData()
            const filterCats = catData.filter(x=>x.cityID==findCity.cityID)

            store.dispatch(setServiceCategoriesData(filterCats))

            if(filterCats.length>1){

                store.dispatch(setViewServiceCategoriesData(true))
                setLoading(false)

            }else{

                const subCatData = await getSubCategoryData()
                const filterSubCats = subCatData.filter(x=>x.CategoryID==filterCats[0].categoryID)

                store.dispatch(setServiceSubCategoriesData(filterSubCats))

                setLoading(false)
                setProceedToShopValue(true)

                if(setView){

                    setView(false)
                }
            }

            
        }else{

            setLoading(false)
            if(setError){
                setError(true)
            }

            return toast.error('Service not available in your area !!')

        }

        
    }catch(err){

        console.log(' ! ERR : ',err)
        setLoading(false)
    }
}


export const updateShopLocalData=(data,key)=>{


    const localStorageData1 = localStorage.getItem('shopData')
    let localData1 = localStorageData1!=null? JSON.parse(localStorageData1) : {}
    localData1 = {...localData1,[key]:data}
    
    localStorage.setItem('shopData',JSON.stringify(localData1))
    

}


export const checkForNullvalue=(value,returnInCaseOfNull)=>{

    if(value==null){

        return returnInCaseOfNull

    }else{

        if(value && value!='undefined'){

            const valueToReturn = JSON.parse(JSON.stringify(value))
            return valueToReturn 
    
        }

        return returnInCaseOfNull

    }
}






export const getApiForProductTypeData=(title)=>{

    return `getProData/${title}`

}


export const getProductLevelData=(type)=>{

    const storeData = store.getState().ShopV2
    const mainData = storeData.mainData
    console.log(storeData,'mainData');
    
    return mainData[type] ?? []
    
}

export const filterProductLevelData=(data,payload)=>{

    let filteredData 

    
    if(payload?.notToFilter){

        filteredData = data
    
    }else{

        const ids = payload.ids.split(',')
        
        filteredData = data.filter(x=>ids.includes(x.uid))
    }
    

    return filteredData ?? []

}


export const getProDataMain=(payload)=>{


    return new Promise((resolve,reject)=>{
console.log(payload.classification,'payload.classification');
        const data = getProductLevelData(payload.classification)
        
        const filteredData = filterProductLevelData(data,payload)
    
        resolve({data:filteredData})

    })

}


export const productIsInCart=(product)=>{

    const storeData = store.getState().CartSlice
    const cart = storeData.data

    const find = cart.find(x=>x.uid == product?.uid)

    return find
}

export const handleConfirmText = (text) => {

    const MySwal = withReactContent(Swal)

    return MySwal.fire({
      title: 'Are you sure?',
      text: text ?? "Your Items from Cart will be Removed as you select products from other category. Do You want to continue?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Continue!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        
        return true
      }
    })
}

export const getBookingLabelsData = async()=>{

    return new Promise(async (resolve,reject)=>{

        const response = await Services.getBookingLabelsData()

        if(response){

            resolve(response.data)
        
        }

    })
}

export const tagsByClassification = async(payload)=>{

    return new Promise(async (resolve,reject)=>{

        const response = await Services.getTagsByClassification(payload)

        if(response){

            resolve(response.data)
        
        }

    })
}

export const setBookingLabelsData=async()=>{

    const bookingData = store.getState().CheckoutSlice.bookingData


    if(bookingData.length){

        return
    }else{

        const response = await getBookingLabelsData()
        if(response){

            store.dispatch(setBookingData(response))

        }
    }

}


export const removeItem = async (itemUid,data   )=>{

    const cartData = JSON.parse(JSON.stringify(data))
    
    const itemIndex = data.findIndex(x=>x.uid==itemUid)
     
    if(itemIndex!=-1){
        cartData.splice(itemIndex,1)
    } 

    return cartData.map(x=>({...x,loading:false})) 
    
}

const addItem=async (pro,cartData,itemQty)=>{

    const bookingData = store.getState().CheckoutSlice.bookingData
    const pixelVisit = store.getState().ShopV2.pixelVisit
    let fbid = (new URLSearchParams(window.location.search)).get("fbclid")
    
    if(fbid && !pixelVisit)
    {
        store.dispatch(setPixelVisit(true))
        pixelAddToCart(pro)
    }
    else if(pixelVisit)
    {
        pixelAddToCart(pro)
    }
    const product = Object.assign({},pro)
    const cart = JSON.parse(JSON.stringify(cartData))
    let bookingLabels = bookingData.length? bookingData : await getBookingLabelsData()

    const select = bookingLabels.find(x=>x.booking_id==product.subcat_multiselectid)
    const findItem = cart.find(x=>x.uid==(product?.value || product?.uid))


    if(findItem){

        // check if item already added
        if(findItem.qty<findItem.max_qty_allowed){
            
            if(findItem.qty){
    
                findItem.qty += 1
            
            }else{
    
                findItem.qty=1
    
            }
            
            findItem.totalPrice = findItem.qty * findItem.price
            store.dispatch(setCartData(cart))
    
        }else{
            
            return toast.error(`${product.genericName || product.label} not Allowed More than ${product.max_qty_allowed}`)
       
        }

    }else{

        // check if this service category allow to add more than one item
        if(cart.length!=0 && (select.is_multi==0 || select.is_multi==null)){
        
            if(product.max_qty_allowed>0){
        
                product.qty= 1
                product.totalPrice = 1 * product.price
                store.dispatch(setCartData([product]))
        
            }else{
    
                return toast.error(`${product.genericName || product.label} not Allowed More than ${product.max_qty_allowed}`)
            }
              
          }else{
            
            if(product.max_qty_allowed>0){
            
                product.qty= 1
                product.totalPrice =1 * product.price
                store.dispatch(setCartData([...cart,product]))
                
              }else{
    
                // return toast.error(`${product.genericName || product.label} not Allowed More than ${product.max_qty_allowed}`)
                return toast.error(`${product.genericName || product.label} Out of Stock !!`)
             }
    
          }

    }

}

export const addItemtoCart=async (product)=>{

    console.log(product,'dddddddddddddddddd')
    

    const storeData = store.getState().CartSlice
    let cart = storeData.data
    
    const id = product.uid
    const sameCatItem = cart.find(x=>x.catId!=product.catId)


    if(sameCatItem){

        const choice = await handleConfirmText()

        if(choice){

            cart = []
            addItem(product,cart)

        }


    }else{

        if(productIsInCart(product)){

            const data=await removeItem(product.uid,cart)
            store.dispatch(setCartData(data))

            setTimeout(()=>store.dispatch(setCartData(data.map(x=>({...x,loading:false})))),200)
            
        
        }else{

            addItem(product,cart)

        }
    }

}

const getFilters=(filterOn)=>{

    const obj={

        'Categories':'cat_id',
        'Category':'cat_id',
        'Products':'prod_id',
        'products':'prod_id',
        'brands':'brand_id',
        'Brands':'brand_id',
        'tags':'tag_id',
        'tag':'tag_id',
        'groups':'group_id'

    }
    return obj[filterOn]
}

export const getItemsData=async(props)=>{

    const {filterOn,id,setLoading,start,pageSize,setItems,setNoMoreItems} = props

    try{

        setLoading(true)

        const payload={
            
            start,
            end:pageSize,
            filter:getFilters(filterOn),
            filterValue:id
        }

        const response = await Services.getItemsDataForShopV2(payload)
        
        if(response){

            const result = response.data.data
            
            if(result.length==0){

                setNoMoreItems(true)
            }

            if(result.length){

                setItems((prev)=>[...prev,...result])
            }
            
            setLoading(false)

        }
        

    }catch(err){

        console.log(err)
        setLoading(false)

    }

}

export const filterItemsOnParams=(data,setData,setLoading,tag)=>{

    setLoading(true)

    let copyData = JSON.parse(JSON.stringify(data))
    const storeData = store.getState().ShopV2
    const filterParams = storeData.filterParams
    const alphabet = filterParams.alphabet

    if(tag &&  tag!='All'){
        copyData = copyData=copyData.filter(x=>x.tag.includes(tag))
        console.log(copyData,'copyData');
    }

    let parsedData = copyData.filter(x=>x.genericName.toLowerCase().startsWith(alphabet.toLowerCase()))

    setData(parsedData)
    setLoading(false)

}


const getPromoCode= async ()=>{
    const result = Services.getPromoCodeData()
    return result
}


export const applyPromo=async(promo)=>{

    let discount = 0;
    
    const storeData = store.getState().ShopV2
    const checkOutStore = store.getState().CheckoutSlice

    let orderDetails = {...storeData.orderDetails}
    const invoice = orderDetails.invoice

    const customerInfo = checkOutStore.customerInfo

    if(!promo?.length)
    return toast.error("Enter Promo to Apply !!")

    const promoCodes = await getPromoCode()
    const parsedData = promoCodes.data.data
    const filteredData = parsedData.find(x=>x.promo_code==promo && x.subcat_id==storeData.selectedServiceSubCat && x.publishtype=='PUBLISHED')

    if(!filteredData)
    return toast.error('Invalid Promo !!')

    const promoEndDate= filteredData.end_date
    
    
    if(new Date(promoEndDate)-new Date()<0 || filteredData.count<=0)
    return toast.error("Promo Expired !!")


    if((filteredData.customer_group == null || filteredData.customer_group == 0) && filteredData.customerid == customerInfo.id){ 
        
        discount = calculateDiscount(filteredData)
        
    }else if((filteredData.customerid == null || filteredData.customerid == 0) && (filteredData.customer_group == customerInfo.group_id && customerInfo.cus_group_verification_status==1)){ 
    
        discount = calculateDiscount(filteredData)
        
    }else if(filteredData.customerid == 0 && filteredData.customer_group == 0){ 
        
        discount = calculateDiscount(filteredData)
        
    }else{

        return toast.error('Not Applicable for the Discount !!')

    }
    
    orderDetails.invoice = {...invoice,discount:filteredData['promo_value'],discountType:filteredData['promo_type']}

    store.dispatch(setOrderDetails(orderDetails))
    store.dispatch(setPromo(filteredData))
    
}

export const calculateDiscount=(promo)=>{

    if(promo.length==0)
    return 0
    
    if(promo?.promo_type?.toUpperCase()=='VALUE'){

        return promo['promo_value']
    
    }else{

        const storeData = store.getState().ShopV2
        const orderDetails = storeData.orderDetails
        const invoice = orderDetails.invoice
        const payment_value = invoice?.productCharges
        const calculatedDiscount = payment_value * (Number(promo['promo_value'])/100)

        return calculatedDiscount.toFixed(2)

    }

}

export const calculateAfterDiscount = ()=>{

    const storeData = store.getState().ShopV2
    const orderDetails = storeData.orderDetails
    const invoice = orderDetails.invoice
    const discount = invoice?.discount
    const discount_type = invoice?.discountType

    let afterDisc = Number(invoice?.productCharges)-calculateDiscount({promo_type:discount_type,promo_value:discount}) ?? 0

    if(afterDisc<0){

        afterDisc = 0 
    
    }

    return (afterDisc)


}


export const calculateTotalBill=()=>{

    const storeData = store.getState().ShopV2
    const orderDetails = storeData.orderDetails
    const invoice = orderDetails.invoice
    
    const totalBill = (calculateAfterDiscount() ?? 0) + Number(invoice?.distanceCharges) + Number(invoice?.serviceCharges)
    
    return `${totalBill}`
}



const registerCustomerAPI=async (payload)=>{

    const response = await Services.postRegisterCustomer(payload)
    return response
}

export const registerCustomer=async (payload)=>{

    const customerInformation = store.getState().CheckoutSlice.customerInfo

    const result = await registerCustomerAPI({...customerInformation,...payload})

    
    if(result?.status==200){
        
        store.dispatch(setCustomerInfo({
            
            id:result.data.data,
            group_id:result.data.panel,
            cus_group_verification_status:result.data.panel_status

        }))
        

        return true
        
    }

    return false

    
}


export const addProductsToRespectiveMultiSelect =async (data,multiSelectData) =>{

    const cartData = JSON.parse(JSON.stringify(data))
    const multiSelects = JSON.parse(JSON.stringify(multiSelectData))

    const itemsString = multiSelects.map(multiSelect=>{

        const items = cartData.filter(item=>item.subcat_multiselectid==multiSelect.booking_id)

        const itemString = items.filter(x=>x?.qty>0).map(item=>{
            return `${item.genericName}(${item.qty})`
        })
        const itemIds = items.filter(x=>x?.qty>0).map(x=>{
            return `${x.uid}`
        })
        multiSelect.values="["+itemString.toString().concat(']')
        multiSelect.selected_id="["+itemIds.toString().concat(']')
        multiSelect.item_order = items.filter(x=>x?.qty>0).map(item=>({
            item_id:item.uid,
            quantity:item.qty,
            price:item.price
        }))
    })
    
    return multiSelects
    
}

export const saveCartDetailsToOrder=async (labels)=>{

    const storeData = store.getState().ShopV2
    const cartStore = store.getState().CartSlice
    const items = cartStore.data

    const productCharges = getSumByKey(items,'totalPrice')
    const selectedServiceSubCat= storeData.selectedServiceSubCat
    const serviceSubCategoriesData = storeData.serviceSubCategoriesData

    const selectedSubcat = serviceSubCategoriesData.find(x=>x.subCatID==selectedServiceSubCat)
            
    
    let jobDetail = JSON.parse(JSON.stringify(storeData.orderDetails))

    const findLabel = labels.find(x=>x.subcatid==selectedServiceSubCat && x.type=='multiselect')
    const multiSelects = labels.filter(x=>x.subcatid==selectedServiceSubCat && x.type=='multiselect')
    
    if(selectedSubcat){

        
        jobDetail.invoice['serviceCharges'] = selectedSubcat.serviceCharges

    }

    if(findLabel){

        const response = await addProductsToRespectiveMultiSelect(items,multiSelects)

        jobDetail.storyChoosed[findLabel.type] = response
        jobDetail.invoice['productCharges'] = productCharges
        

        store.dispatch(setOrderDetails(jobDetail))

    }


}

export const confirmButtonFunctionForLocation=(locObj)=>{

    const storeData = store.getState().ShopV2
    const cartStore = store.getState().CartSlice
    const cart = cartStore.data
    const productCharges = getSumByKey(cart,'totalPrice')

    const selectedServiceSubCat= storeData.selectedServiceSubCat
    const serviceSubCategoriesData = storeData.serviceSubCategoriesData

    const selectedSubcat = serviceSubCategoriesData.find(x=>x.subCatID==selectedServiceSubCat)

    let obj={
        origin:selectedSubcat?.calculate_from ?? '',
        destination:locObj.latLng
    }

        const directionsService = new google.maps.DirectionsService();

        directionsService.route(
            {
              origin: obj.origin,
              destination: obj.destination,
              travelMode: google.maps.TravelMode.DRIVING
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) { 
                
                const distance = result?.routes[0].legs[0].distance.text
                let finalDistance =  parseInt(distance.split(" ")[0].replace(/,/g, ""))

                if(distance.includes('k')){

                    finalDistance = finalDistance

                }else{

                    finalDistance = finalDistance/1000
                }


                let jobDistanceCharges = finalDistance * selectedSubcat.rate_per_km
                const productValue = productCharges
                const minCharge = selectedSubcat.minimum_distance_chargeable
                const maxDistanceCovered = selectedSubcat.maximum_distance_covered

                if(productValue>=selectedSubcat['minimum_value'] && (finalDistance*1000)<=minCharge && minCharge>0){
                    jobDistanceCharges=0
                }

                if((finalDistance*1000) < maxDistanceCovered){

                    let jobDetail = JSON.parse(JSON.stringify(storeData.orderDetails))

                    const invoiceObj={

                        ...jobDetail.invoice,
                        distanceCharges:  productValue<selectedSubcat?.minimum_value? (selectedSubcat?.calculate_per_km==1? jobDistanceCharges.toFixed(2) : selectedSubcat.rate_per_km) : 0,
                        distance:finalDistance 
                    }
                    
                    jobDetail.invoice = invoiceObj
                    
                    store.dispatch(setOrderDetails(jobDetail))
                    store.dispatch(setOrderValidity(false))

                }else{

                    store.dispatch(setOrderValidity(true))
                    return toast.error("Service is Not Availaible in Requested Area !!")
                }

              }
            }
        )

}

const createDescriptionForNewJob=(storeData)=>{

    const jobData=storeData.orderDetails.storyChoosed
    const cart = store.getState().CartSlice.data
    
    let description=""

    jobData?.location && jobData?.location.length >0? jobData?.location?.map(x=>description +=' '+x.values) || '':''

    cart.map(x=>{
        description +=' '+`${x.genericName || x.label}[${x.qty}]`
    })
    return description
}


const storyChoosedDataForNewJob=(storeData)=>{

    const jobStory = storeData.orderDetails.storyChoosed
    
    let arr=[]

    for(let story in jobStory){

        const storyData = jobStory[story]

        storyData.map(x=>{

            let obj={}
            obj.values= story!='label'? (x.values || '') : x.sectionValue
            obj.type=x.type
            obj.selected_id=x.selected_id || ''
            obj.title=x.title
            obj.order_no=x.seqNumber,
            obj.story_id=x.booking_id ?? x.story_id

            if(x.type=='multiselect'){
                obj.item_order = x.item_order
            }

            arr.push(obj)
        })

    }

    return arr

}

export const getCustomerWallet = async (id)=>{

    try{

        const response = await Services.getCustomerWallet({id})

        if(response){

            const data = response.data
            const wallet = data[0]?.arrears ?? 0

            return wallet
        }
        
    }catch(err){
        console.log(" Wallet  Err : ",err)
        toast.error('Something Went Wrong !!')
    }
    
}


export const viewPaymentModal=async (customerID)=>{

    const storeData = store.getState().ShopV2
    const checkoutSlice = store.getState().CheckoutSlice
    const customerInformation= checkoutSlice.customerInfo
    const orderDetails = storeData.orderDetails
    const invoice = orderDetails.invoice
    const jobDetail = storeData.jobDetail

    let afterDisc = invoice.productCharges - calculateDiscount(storeData?.promo ?? '')

    if(afterDisc<0){

        afterDisc = 0 
    
    }

    const productCharges = afterDisc
    
    const customerWallet = await getCustomerWallet(customerID)
    
    const amount  = Number(productCharges) + Number(invoice.distanceCharges || 0)
    let totalAmount = amount - Number(customerWallet)

    const email = ''
    const desc = ''
    const baseURL = localStorage.getItem('baseURL')
    const server = baseURL.includes('test')?'rozaana':'rozaana_live'

    const headRoute = themeConfig.app.headRoute
    const paymentURLs = themeConfig.app.paymentURLs

    const paymentURL = paymentURLs[headRoute] 

    if(totalAmount>0){

        const url =`${paymentURL}?jobID=${jobDetail?.uid}&mobileNumber=${customerInformation.phone}&transactionAmount=${totalAmount}&customerEmailAddress=${email}&transactionDescription=${desc}`;
       
        window.open(url, '_blank');

    }

    
}

const orderValidToProceed=(setLoading)=>{

    const storeData = store.getState().ShopV2
    const orderDetails = storeData.orderDetails
    const storyChoosed = orderDetails.storyChoosed
    const multiSelects = storyChoosed.multiselect
    
    for (const key in storyChoosed) {
       
        if (Object.hasOwnProperty.call(storyChoosed, key)) {
            
            const label = storyChoosed[key];
            console.log('dddddddddddddddddddddddddddddddd',key)
            for (let index = 0; index < label.length; index++) {
        
                const element = label[index];
                
        
                if(element.is_mandatory==1 && !element.values){
        
                    toast.error(`Select ${element.sectionValue} to Proceed !!`)
                    store.dispatch(setOrderValidity(false))
                    setLoading(false)
                    return false
        
                }
        
            }

        }
    }
    
    return true
}

export const submitOrderHandler=async (setLoading,navigate)=>{

    try{

        setLoading(true)

        const storeData = store.getState().ShopV2
        const cartStore = store.getState().CartSlice
        const checkoutSlice = store.getState().CheckoutSlice
        const customerInformation= checkoutSlice.customerInfo
        
        const cart = cartStore.data

        const orderDetails = storeData.orderDetails
        
        const storyChoosed = orderDetails.storyChoosed
        const invoice = orderDetails.invoice

        const orderLocation = storyChoosed.location
        const orderTime = storyChoosed.time
        const orderProducts = storyChoosed.multiselect

        const productCharges = getSumByKey(cart,'totalPrice')

        const selectedServiceSubCat= storeData.selectedServiceSubCat
        const serviceSubCategoriesData = storeData.serviceSubCategoriesData

        const selectedSubcat = serviceSubCategoriesData.find(x=>x.subCatID==selectedServiceSubCat)

        if(!orderValidToProceed(setLoading))
        return 


        const payload={

            customer_location:'',
            description:createDescriptionForNewJob(storeData),
            subcatid:selectedServiceSubCat,
            slotId:'',
            customerid:customerInformation.id,
            customer_id:customerInformation.id,
            collection_point_city:'',
            collection_point_location:'',
            delivery_point_location: orderLocation && orderLocation.length>0? orderLocation[0].selected_id:'0,0',
            rating:'',
            insert_date: getFormattedDate(new Date()),
            jobdate:getFormattedDate(orderTime[0].values),
            earning:'',
            receivable_amount:'',
            estimated_mileage_charges:invoice.distanceCharges,
            mileage_charges:invoice.distanceCharges,
            service_charges:invoice.serviceCharges,
            distance_covered:invoice.distance,
            job_status:'WAITING',
            status:1,
            payment_status:0,
            amount_before_discount:invoice.productCharges,
            payment_value:invoice.productCharges,
            promo:storeData?.promo?.promo_code || '',
            promo_code:storeData?.promo?.promo_code || '',
            discount_type:storeData?.promo?.promo_type ?? '',
            discount:storeData?.promo?.promo_value ?? 0,
            // payment_value:storeData.productCharges-storeData.invoiceDetails.discount,
            slot_id:'',
            slot_status:'',
            storyChoosed:storyChoosedDataForNewJob(storeData),
            paymenttype:customerInformation.paymentType,
            requested_emp_id:''

        }

        console.log(' *** ++ == PAYLOAD :    ',payload)

        const response = await Services.postNewJob(payload)

        if(response){

            const jobid = response.data.jobid || response.data.data
            const track_code = response?.data?.track_code

            const jobResponse = {
                
                status:response.status,
                jobId:jobid,
                track_code:track_code
            
            }

            store.dispatch(setJobDetails(jobResponse))

            if(customerInformation.paymentType=='online'){

                viewPaymentModal(customerInformation?.id)

            }

            setTimeout(()=>{

                let routeToNvigate = themeConfig.app.headRoute

                if(routeToNvigate.length==0){
                    routeToNvigate = '/'
                }

                store.dispatch(setCartData([]))
                store.dispatch(setRefreshShopData())
                navigate(routeToNvigate)
                store.dispatch(setViewNewJobModal(true))
                
            },1500)

            setLoading(false)
        }

    }catch(err){

        console.log('!! ERR : ',err)
        setLoading(false)
    }

}



const getLocationName = (lat, lng) => {

    return fromLatLng(lat, lng).then(response => {

        const results = response.results[0]
        const address_components = results.address_components

        let city = -1
        
        const currentAddress = results.formatted_address

        address_components.map(x=>{

            if (x.types[0] == "locality"){
                
                city = x.long_name
             
            }
        })

        return {city,currentAddress}
        
    }).catch(error => {
        console.log('location name error: ', error)
        return {city:'',currentAddress:''}
    })
}

export const getCurrentLocation=async()=>{

    return  new Promise((resolve,reject)=>{
 
            navigator.geolocation.getCurrentPosition(
                async position => {
                
                    const obj={
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    
                    const {city,currentAddress} = await getLocationName(obj.lat,obj.lng)
                    resolve({city,currentAddress,latLng:`${obj.lat},${obj.lng}`})
        
            },(err)=>{

                if(err.code==1){

                    resolve(-1)
                } 
            }
            )
        
    })
    
}

export const NewJobColors=[

    'WAITING',
    'APPROVED',
    'INPROCESS',
    'TRANSIT',
    'ARRIVED',
    'STARTED',
    'COMPLETE',
    'COMPLETED', 
    'ENDED'
]

const AssignedJobColors=[

    'APPROVED',
    'INPROCESS',
    'TRANSIT',
    'ARRIVED',
    'STARTED',
    'COMPLETE',
    'COMPLETED', 
    'ENDED'
]

const TransitJobColors=[

    'TRANSIT',
    'ARRIVED',
    'STARTED',
    'COMPLETE',
    'COMPLETED', 
    'ENDED'
]

const ArrivedJobColors=[

    'ARRIVED',
    'STARTED',
    'COMPLETE',
    'COMPLETED', 
    'ENDED'
]

const StartedJobColors=[

    'ARRIVED',
    'STARTED',
    'COMPLETE',
    'COMPLETED', 
    'ENDED'
]


const CompletedJobColors=[

    'COMPLETED', 'COMPLETE',
    'ENDED'
]

let waitingJobData={
    time:'02,1999',
    title: function(){
        return `Order confirmed -  ${this.time!=null && this.time.length ? formatDate(this.time) : ''}`
    },
    content: 'Your order has been placed successfully.',
    icon: <img src={OrderPosted} width={40} height={40} />,
    meta: '',
    customContent:'',
    status:NewJobColors
}

const assignedJobData={
time:'',
title: function(){
    return `Order Assign to Rider  ${this.time!=null && this.time.length ? formatDate(this.time) : ''}`
},
content: 'Your order has been assigned to rider successfully.',
icon: <img src={OrderAssigned} width={40} height={40} />,
meta: '',
customContent:'',
status:AssignedJobColors
}

const transitJobData={
time:'',
title: function(){
    return `Rider is on the way  ${this.time!=null && this.time.length ? formatDate(this.time) : ''}`
},
content: 'Rider is on the way towards you.',
icon: <img src={RiderOnWay} width={40} height={40} />,
meta: '',
customContent:'',
status:TransitJobColors
}

const riderArrived={
time:'',
title: function(){
    return `Rider Arrived  ${this.time!=null && this.time.length ? formatDate(this.time) : ''}`
},
content: 'Rider has arrived at your location.',
icon: <img src={RiderArrived} width={40} height={40} />,
meta: '',
customContent:'',
status:ArrivedJobColors
}

const jobStarted={
time:'',
title: 'Order Started',
content: 'Order has been Started.',
icon: <img src={RiderOnWay} width={40} height={40} />,
meta: '',
customContent:'',
status:StartedJobColors
}

const jobCompletedData={
time:'',
title: function(){
    return `Order Completed  ${this.time!=null && this.time.length ? formatDate(this.time) : ''}`
},
content: 'Your order has been completed successfully.',
icon: <img src={OrderCompleted} width={40} height={40} />,
meta: '',
customContent:'',
status:CompletedJobColors
}


const getOrderDetail=async(orderId)=>{

    const result  = await Services.getOrderDetails(orderId);

    return result
}


export const getOrderDetailById=async(orderId)=>{

    const response = await getOrderDetail(orderId)

    if(response){
    
        

        const result = response.data.result
        const times = response.data.times

        if(result.length!=0){

            let arr=[{...waitingJobData,time:result[0].insert_date},{...assignedJobData,time:result[0].assigned_time},transitJobData,{...riderArrived, time:times.find(x=>x.job_status=="ARRIVED")?.mTime||''},{...jobCompletedData, time:times.find(x=>CompletedJobColors.includes(x.job_status))?.mTime||''}]

            const jobStatus = result[0].current_job_status==null? result[0].jobstatus : result[0].current_job_status
    
            if(result.length)
            return {data:arr,status:jobStatus}
    
        }

        return {data:[],status:-1}
    }
}



export const searchItem=async (input,setLoading)=>{

    try{
        
        setLoading(true)
        const itemsResponse = await Services.getSearchItems(input.toLowerCase())

        if(itemsResponse?.data){

            return itemsResponse.data.result
        }
        
        // return itemsResponse
    }catch(err){

        console.log(' eRR :',err)
    }

}


export const getTimeSlotData=()=>{
    // Services.getTimeSlotsData().then(res=>{

    //     if(res){
    //         store.dispatch(setTimeSlotData(res?.data))
    //     }
    // })
}

export const toSetTimeSlotValuesData=(timeSlotData)=>{

    const selectedSubcat = localStorage.getItem('selectedServiceSubCat')

    if(timeSlotData.length){
        
        store.dispatch(setTimeSlotLoading(true))

        const findTimeSlot =  timeSlotData.find(x=>x.subCatId==selectedSubcat)

        if(findTimeSlot){

            const timeSlotValuesObj={

                lagTime:parseInt(findTimeSlot.interval_mins),
                startTime:findTimeSlot.slot_start+":00",
                endTime:findTimeSlot.slot_end+":00"

            }

            store.dispatch(setTimeSlotValues(timeSlotValuesObj))
            store.dispatch(setTimeSlotLoading(false))
        
        }

    }

}