// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import ShopV2 from '../components/redux/store';
import CartSlice from '../components/Cart/redux/slice';
import CheckoutSlice from '../components/Checkout/store/index'

const rootReducer = { 
    
    navbar, 
    layout,
    ShopV2,
    CartSlice,
    CheckoutSlice
}

export default rootReducer
